<template>
    <cms-block v-bind="$props" @block-selected="selectBlock">
        <b-row>
            <b-col :cols="cols" :order="block.mediaProxy.layout === 'row-right' ? 2 : 0" class="col flex" :style="imageContainerStyle" :class="{ 'mb': isMobileLayoutOrPreview || block.mediaProxy.layout === 'default'}">
                <cms-component-image :component="block.image" v-bind="componentProps"/>
            </b-col>
            <b-col :cols="cols" class="col flex" :class="{ 'mt': isMobileLayoutOrPreview || block.mediaProxy.layout === 'default'}">
                <cms-component-text :component="block.title" v-bind="componentProps"/>
                <cms-component-text :component="block.subtitle" v-bind="componentProps"/>
                <cms-component-text :component="block.description" v-bind="componentProps"/>
                <cms-component-button :component="block.button" v-bind="componentProps"/>
            </b-col>
        </b-row>
    </cms-block>
</template>

<script>
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";
import CmsComponentButton from "@/components/editor/rendering/components/cms-component-button.vue";
import CmsComponentImage from "@/components/editor/rendering/components/cms-component-image.vue";
import CmsComponentText from "@/components/editor/rendering/components/cms-component-text.vue";

// cms-block-text-and-image #hot-reload-debug
export default {
    name: `cms-block-text-and-image`,
    components: {CmsComponentImage, CmsComponentButton, CmsComponentText, CmsBlock},
    extends: CmsBlock,
    computed: {
        imageContainerStyle() {
            let style = {};
            if (this.block.image.maxWidth && !this.isMobileLayoutOrPreview && this.block.mediaProxy.layout !== `default`) {
                style.maxWidth = `calc(${this.block.image.maxWidth}px + var(--block-spacing) * 2)`;
            }
            return style;
        },
        cols() {
            if (this.isMobileLayoutOrPreview) {
                return 12;
            }
            return this.block.mediaProxy.layout === `default` ? 12 : ``;
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
